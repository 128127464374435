export const SteeringWheelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zM8 13l-3.938.001A8.004 8.004 0 0 0 11 19.938V16a3 3 0 0 1-3-3zm11.938.001L16 13a3 3 0 0 1-3 3l.001 3.938a8.004 8.004 0 0 0 6.937-6.937zM14 12h-4v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1zm-2-8a8.001 8.001 0 0 0-7.938 7H8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h3.938A8.001 8.001 0 0 0 12 4z"
      fill="#1e293b"
    />
  </svg>
);

export const ListCustomerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20"
    height="20"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <path
      d="M50,23.333c-7.363,0-13.333,5.97-13.333,13.333C36.667,44.032,42.637,50,50,50s13.333-5.968,13.333-13.333
    C63.333,29.303,57.363,23.333,50,23.333z M50,43.333c-3.682,0-6.667-2.985-6.667-6.667S46.318,30,50,30s6.667,2.985,6.667,6.667
    S53.682,43.333,50,43.333z"
      fill="#1e293b"
    />
    <path
      d="M50,10c-22.093,0-40,17.909-40,40.001C10,72.09,27.907,90,50,90c22.09,0,40-17.91,40-39.999C90,27.909,72.09,10,50,10z
     M30,76.621V70c0-3.682,2.985-6.667,6.667-6.667h26.666C67.015,63.333,70,66.318,70,70v6.621c-5.576,4.196-12.487,6.712-20,6.712
    S35.573,80.817,30,76.621z M76.66,69.944c-0.029-7.334-5.983-13.277-13.327-13.277H36.667c-7.344,0-13.301,5.94-13.327,13.281
    c-4.173-5.566-6.673-12.458-6.673-19.947c0-18.411,14.922-33.335,33.333-33.335c18.408,0,33.333,14.924,33.333,33.335
    C83.333,57.49,80.833,64.382,76.66,69.944z"
      fill="#1e293b"
    />
  </svg>
);

export const MapCustomerIcon = ({ x, y }: { x: number; y: number }) => (
  <svg
    style={{ position: "relative", zIndex: 1 }}
    x={x - 0.5}
    y={y - 0.5}
    fill="#000000"
    width="2"
    height="2"
    viewBox="0 0 56.00 56.00"
    xmlns="http://www.w3.org/2000/svg"
    transform="matrix(1, 0, 0, 1, 0, 0)"
    dangerouslySetInnerHTML={{
      __html: `
      <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.199999999999999,4.199999999999999), scale(0.85)">
      <rect x="0" y="0" width="56.00" height="56.00" rx="28" fill="#ffffff" strokewidth="0"/>
      </g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.22400000000000003"/>
      <g id="SVGRepo_iconCarrier">
      <path d="M 27.9999 51.9063 C 41.0546 51.9063 51.9063 41.0781 51.9063 28 C 51.9063 14.9453 41.0312 4.0937 27.9765 4.0937 C 14.8983 4.0937 4.0937 14.9453 4.0937 28 C 4.0937 41.0781 14.9218 51.9063 27.9999 51.9063 Z M 27.9999 14.5 C 32.4765 14.5 36.0390 18.4375 36.0390 23.1719 C 36.0390 28.2109 32.4999 32.0547 27.9999 32.0078 C 23.4765 31.9609 19.9609 28.2109 19.9609 23.1719 C 19.9140 18.4375 23.4999 14.5 27.9999 14.5 Z M 42.2499 41.8750 L 42.3202 42.1797 C 38.7109 46.0234 33.3671 48.2266 27.9999 48.2266 C 22.6093 48.2266 17.2655 46.0234 13.6562 42.1797 L 13.7265 41.8750 C 15.7655 39.0625 20.7812 35.9922 27.9999 35.9922 C 35.1952 35.9922 40.2343 39.0625 42.2499 41.8750 Z"/>
      </g>
    `,
    }}
  />
);

export const MapDestinationIcon = ({ x, y }: { x: number; y: number }) => (
  <svg
    style={{ position: "relative", zIndex: 2 }}
    x={x - 0.5}
    y={y - 1.5}
    width="2"
    height="2"
    viewBox="0 0 14 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    dangerouslySetInnerHTML={{
      __html: `
      <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Two-Tone" transform="translate(-649.000000, -1262.000000)">
          <g id="Communication" transform="translate(100.000000, 1162.000000)">
            <g id="Two-Tone-/-Communication-/-location_on" transform="translate(544.000000, 98.000000)">
              <g>
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M12,4 C9.24,4 7,6.24 7,9 C7,11.85 9.92,16.21 12,18.88 C14.11,16.19 17,11.88 17,9 C17,6.24 14.76,4 12,4 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
                  fill="#ffffff"></path>
                <path
                  d="M12,2 C15.87,2 19,5.13 19,9 C19,14.25 12,22 12,22 C12,22 5,14.25 5,9 C5,5.13 8.13,2 12,2 Z M7,9 C7,11.85 9.92,16.21 12,18.88 C14.12,16.19 17,11.88 17,9 C17,6.24 14.76,4 12,4 C9.24,4 7,6.24 7,9 Z M12,11.5 C10.6192881,11.5 9.5,10.3807119 9.5,9 C9.5,7.61928813 10.6192881,6.5 12,6.5 C13.3807119,6.5 14.5,7.61928813 14.5,9 C14.5,10.3807119 13.3807119,11.5 12,11.5 Z"
                  fill="#1D1D1D"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    `,
    }}
  />
);
